<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
      </h3>

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs>
            <gtTab title="Hasil Tindaklanjut">
              <!-- <div class="col-md-12">Detail Surat</div> -->
              <div class="col-md-12 pull-left">
                <small class="text-muted">Agenda Number</small>
                <h6>
                  {{
                    disposisi.agenda_number === ""
                      ? "-"
                      : disposisi.agenda_number
                  }}
                </h6>
                <small class="text-muted">Nomor Surat</small>
                <h6>
                  {{
                    surat.nomor_surat === undefined ? "-" : surat.nomor_surat
                  }}
                </h6>
                <small class="text-muted">Isi Ringkas</small>
                <h6>
                  {{
                    surat.description === undefined ? "-" : surat.description
                  }}
                </h6>
                <br />
                <br />
                <hr />
              </div>
              <br />
              <br />
              <div class="col-md-12"><b>Detail Ringkasan Struktural</b></div>
              <!-- 
              <div class="col-md-12 pull-left">
                <small class="text-muted">Ringkasan Hasil Tindak Lanjut</small>
                <h6>
                  {{ disposisi.lanjuti === "" ? "-" : disposisi.lanjuti }}
                </h6>
              </div> -->

              <!-- List Ringkasan Hasil Tindak Lanjut Level Struktural -->
              <div class="col-md-12 pull-left">
                <table class="table table-striped">
                  <tr>
                    <th>Struktural</th>
                    <th>Ringkasan</th>
                  </tr>
                  <span v-if="disposisi === undefined"></span>
                  <tr
                    v-else-if="!disposisi.hasiltindaklanjuts.length"
                    class="text-center"
                  >
                    <td colspan="4"><i>Ringkasan tidak ada</i></td>
                  </tr>
                  <tr
                    v-for="ringkasans in disposisi.hasiltindaklanjuts"
                    v-bind:key="ringkasans.id"
                  >
                    <td>{{ ringkasans.nama }}</td>
                    <td>{{ ringkasans.ringkasan }}</td>
                  </tr>
                </table>
                <hr />
              </div>
              <!-- List Ringkasan Hasil Tindak Lanjut Level Struktural -->

              <div class="col-md-12"><b>Detail Ringkasan Pelaksana</b></div>

              <!-- List Ringkasan Hasil Tindak Lanjut Level Pelaksana -->
              <div class="col-md-12 pull-left">
                <table class="table table-striped">
                  <tr>
                    <th>Pelaksana</th>
                    <th>Ringkasan</th>
                  </tr>
                  <span v-if="disposisi === undefined"></span>
                  <tr
                    v-else-if="!disposisi.hasiltindaklanjutp.length"
                    class="text-center"
                  >
                    <td colspan="4">
                      <i>Ringkasan tidak ada</i>
                    </td>
                  </tr>
                  <tr
                    v-for="ringkasanp in disposisi.hasiltindaklanjutp"
                    v-bind:key="ringkasanp.id"
                  >
                    <td>{{ ringkasanp.nama }}</td>
                    <td>{{ ringkasanp.ringkasan }}</td>
                  </tr>
                </table>
                <hr />
              </div>
              <!-- List Ringkasan Hasil Tindak Lanjut Level Pelaksana -->

              <!-- List File Hasil Tindak Lanjut -->
              <div class="col-md-12">File Hasil Tindaklanjut</div>
              <div class="col-md-12 pull-left">
                <table class="table table-striped">
                  <tr>
                    <th width="35%">File</th>
                    <th width="15%" class="text-center">Aksi</th>
                  </tr>
                  <span v-if="disposisi === undefined"></span>
                  <tr
                    v-else-if="!disposisi.attachhasiltindaklanjut.length"
                    class="text-center"
                  >
                    <td colspan="4">
                      <i>File tidak ada</i>
                    </td>
                  </tr>
                  <tr
                    v-for="file in disposisi.attachhasiltindaklanjut"
                    v-bind:key="file.filename"
                    style="margin: 5px"
                  >
                    <td>{{ file.filename }}</td>
                    <td class="text-center">
                      <a
                        class="btn btn-success text-white"
                        @click="downloadForce(file.url)"
                        title="Download File"
                        ><i class="fa fa-download text-white"></i></a
                      >&nbsp;
                      <a
                        class="btn btn-info text-white"
                        @click="prevDoc(file.uuid)"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- List File Hasil Tindak Lanjut -->

              <div class="col-md-12"></div>
            </gtTab>
          </gtTabs>
        </div>
      </div>
      <hr />
      <span class="pull-right">
        <button
          type="button"
          @click="goBack()"
          class="btn btn-inverse"
          title="Kembali"
        >
          Kembali
        </button>
      </span>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import axios from "axios";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  components: {
    RotateSquare5,
    gtTab,
    gtTabs,
    TreeView,
    Header,
  },
  data() {
    return {
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isModalVisible: false,
      isConfirmation: false,
      isInformation: false,
      isConfirmation: false,
      isInformation: false,
      ActionChangeable: null,
      printDispoLoading: false,
      headers: {
        nama_file: {
          label: "Nama File",
          sortable: false,
        },
        nama_pengunggah: {
          label: "Nama Pengunggah",
          sortable: false,
        },
        tanggal_upload: {
          label: "Tanggal Upload",
          sortable: false,
        },
      },
    };
  },
  computed: {
    isLeader() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user.isLeader;
    },
    isPersonal() {
      if (this.$store.state.dispositionIn.detail.jenis_dispo == "Personal") {
        return true;
      } else {
        return false;
      }
    },
    canDispo() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
    base() {
      return this.baseUrl;
    },
    state() {
      console.log(this.$store.state.dispositionIn);
      return this.$store.state.dispositionIn;
    },
    surat() {
      return this.state.detail.surat;
    },
    intruksi() {
      return this.state.detail.intruksi;
    },
    disposisi() {
      return this.state.detail;
    },
    personal() {
      return this.state.detail.personal;
    },
    agenda_number() {
      return this.state.detail.surat.agenda_number;
    },
    pengirim() {
      return this.state.detail.sender.name;
    },
    keterangan() {
      return this.state.detail.keterangan;
    },
    catatan() {
      return this.state.detail.note;
    },
    response_dispo() {
      return this.state.detail.response_dispo;
    },
    tujuan() {
      return this.state.detail.tracking.name;
    },
    nomorsurat() {
      return this.state.detail.nomorsurat;
    },
    perihal() {
      return this.state.detail.subject;
    },
    filename() {
      return this.state.detail.filename;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      let text = "-";
      if (this.state.detail.surat != undefined) {
        text = this.formatDate(this.state.detail.surat.tanggal_surat);
      }
      return text;
    },
    tanggaldokumenditerima() {
      let text = "-";
      if (this.state.detail.surat != undefined) {
        if (this.state.detail.surat.tanggal_surat == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.surat.tanggal_surat);
        }
        return text;
      }
    },
    tanggaldokumenselesai() {
      let text = "-";
      if (this.state.detail.surat != undefined) {
        if (this.state.detail.surat.tanggal_surat == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.surat.tanggal_surat);
        }
        return text;
      }
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    showModalNote(option) {
      this.isVisible = true;
      this.modalTitle = "Catatan";
      this.modalContent = "<table class='table table-bordered'>";
      this.modalContent +=
        "<thead><tr><td><b>Penerima</b></td><td><b>Catatan</b></td></tr></thead>";
      this.modalContent += "<tbody>";
      for (let index = 0; index < option.length; index++) {
        if (option[index].is_receiver == 1) {
          this.modalContent +=
            "<tr style='background:aliceblue'><td>" +
            option[index].receiver +
            "</td><td>" +
            option[index].note +
            "</td></tr>";
        } else {
          this.modalContent +=
            "<tr><td>" +
            option[index].receiver +
            "</td><td>" +
            option[index].note +
            "</td></tr>";
        }
      }
      this.modalContent += "</tbody>";
      this.modalContent += "</table>";
    },
    showModalInstruction(option) {
      this.isVisible = true;
      this.modalTitle = "Instruksi";
      this.modalContent = "<table class='table table-bordered'>";
      this.modalContent += "<tbody>";
      for (let index = 0; index < option.length; index++) {
        if (option[index].is_receiver == 1) {
          this.modalContent +=
            "<tr style='background:aliceblue'><td>" +
            option[index].name +
            "</td></tr>";
        } else {
          this.modalContent += "<tr><td>" + option[index].name + "</td></tr>";
        }
      }
      this.modalContent += "</tbody>";
      this.modalContent += "</table>";
    },
    addReceiver() {
      localStorage.setItem("disposition", JSON.stringify(this.state.detail));
      this.$store.dispatch("dispositionSend/onSend", this.state.detail);
    },
    rejectReceiver() {
      this.$store.dispatch("dispositionSend/onReject", this.$route.params);
    },
    onClose() {
      this.isVisible = false;
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("dispositionIn/STATE", state);
      this.$store.dispatch(
        "dispositionIn/getDispositionInById",
        this.$route.params
      );
    },
    addCheck(n) {
      console.log(n);
    },
    goBack() {
      this.$store.dispatch("dispositionIn/onCancel");
    },
    prevDoc(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "document_in",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    downloadForce(url) {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    newTab(file) {
      window.open(file, file);
    },
  },
};
</script>
<style>
.receiver {
  list-style: none;
  margin-left: -20px;
}
.fa-none {
  width: 14px;
  height: 14px;
}
</style>

